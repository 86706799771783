import { join, map, pipe, values, pluck } from 'ramda';
import { camelize } from 'utils/keysConverter';

export function errorMessages(errors) {
  return pipe(camelize, map(join(', ')))(errors);
}

export function joinMessages(errors) {
  return pipe(values, map(join(', ')))(errors);
}

export function joinDataServiceErrors(errors) {
  return pipe(pluck('text'), join(', '))(errors);
}
